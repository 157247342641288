.App {
  background-image: url("./assets/images/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  /* min-height: 100vh; */
  /* height: 100%; */
}
.mainPage {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #ffffff 0%, #ffffff0d 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(14px);
}
